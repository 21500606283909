import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';

import common, { PageWrap } from '../styles/common';

import Masthead from '../components/Masthead';
import CtaBar from '../components/CtaBar';
import LocationsSearchMap from '../components/LocationsSearchMap';
import Button from '../components/Button';

//import Container from 'react-bulma-components/lib/components/container';
//import Columns from 'react-bulma-components/lib/components/columns';

export default ({ locations }) => {
	const [isThanks, setIsThanks] = useState(false);
	useEffect(() => {
		setIsThanks(window.location.hash === '#thanks');
	}, []);
	return (
		<PageWrap>
			<Helmet>
				<title>Contact | Spergel Corporate</title>
			</Helmet>
			<Masthead
				image={`/img/SpergelCorporate_Contact_Masthead.jpg`}
				title={`Contact Us`}
			/>
			<div className="container" style={{ padding: '0 1rem' }}>
				<div className="columns">
					<div className="column is-half">
						<Form>
							{isThanks && (
								<p
									style={{
										color: 'white',
										backgroundColor: common.spergelRed,
										padding: '1rem',
									}}
								>
									Thank you for your inquiry. We will be in contact with you as
									soon as possible.
								</p>
							)}
							<form
								method="POST"
								data-netlify="true"
								name="Contact"
								action="/contact#thanks"
								netlify-honeypot="special"
							>
								<input type="hidden" name="form-name" value="Contact" />
								<input name="special" className="special" />
								<input
									type="hidden"
									name="subject"
									value="New Form Submission From spergelcorporate.ca"
								/>
								<input type="text" name="name" required placeholder="Name" />
								<input type="email" name="email" required placeholder="Email" />
								<input type="tel" name="phone" placeholder="Phone (optional)" />
								<input
									type="text"
									name="company"
									placeholder="Company (optional)"
								/>
								<textarea
									type="text"
									name="inquiry"
									placeholder="Inquiry (optional)"
									rows="10"
								/>
								<div style={{ textAlign: 'center' }}>
									<Button type="submit">SUBMIT</Button>
								</div>
							</form>
						</Form>
					</div>
					<div className="column is-half">
						<RightInner>
							<div style={{ padding: '0 2rem' }}>
								<h2>We’re here to help</h2>
								<p>
									If you are up at night worrying about the future of your
									business, contact a Spergel Licensed Insolvency Trustee to
									find out what options are available to you. We will share our
									years of experience assisting business owners with strategic
									approaches to restructuring their financial affairs. We have
									more than 30 years of experience providing solutions to help
									business owners in financial distress – and an international
									network of professionals at our fingertips. We can help your
									business get back on track with an understandable and
									affordable solution.
								</p>
								<p>Let's start the conversation today</p>
								<p>
									Phone: <a href="tel:4164971660">416-497-1660</a>
								</p>
								<p>
									Email: <a href="mailto:info@spergel.ca">info@spergel.ca</a>
								</p>
								<p>
									Are you looking for help with personal debt help? If you need
									personal debt solutions, please visit us at{' '}
									<a
										href="https://www.spergel.ca/"
										target="_blank"
										rel="noopener noreferrer"
									>
										www.spergel.ca
									</a>
									.
								</p>
							</div>
						</RightInner>
					</div>
				</div>
			</div>
			<LocationsSearchMap locations={locations} />
			<CtaBar>
				<h3>
					Looking for trustee contact information?
					<br />
					<a href="/team">Visit our team page.</a>
				</h3>
			</CtaBar>
		</PageWrap>
	);
};

const Form = styled.div`
	background-color: ${common.slate};
	padding: 3rem 3rem 1rem 3rem;
	max-width: 35rem;
	margin-top: -1rem;
	margin-bottom: -1rem;
	input {
		width: 100%;
		font-size: 1rem;
		border: none;
		padding: 0.75rem;
		margin-bottom: 0.5rem;
		font-family: ${common.baseFont};
	}
	textarea {
		width: 100%;
		font-size: 1rem;
		border: none;
		padding: 0.75rem;
		margin-bottom: 0.5rem;
		resize: none;
		font-family: ${common.baseFont};
	}
	.special {
		display: none;
	}
`;

const RightInner = styled.div`
	padding-left: 0;
	display: flex;
	align-items: center;
	height: 100%;
	padding-top: 2rem;
	padding-bottom: 2rem;
	a {
		color: inherit;
	}
`;
