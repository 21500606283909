import React from 'react';
import Layout from '../components/Layout';
import ContactTemplate from '../templates/ContactTemplate';
import { graphql } from 'gatsby';

const ContactPage = ({ data }) => {
	const locations = data.locations.edges.map((edge) => ({
		city: edge.node.city,
		address_line_1: edge.node.address_line_1,
		address_line_2: edge.node.address_line_2,
		phone: edge.node.phone,
		email: edge.node.email,
		coords: edge.node.coords,
	}));
	return (
		<Layout>
			<ContactTemplate locations={locations} />
		</Layout>
	);
};

export default ContactPage;

export const query = graphql`
	{
		locations: allLocationsJson(sort: { fields: [order], order: ASC }) {
			edges {
				node {
					city
					address_line_1
					address_line_2
					phone
					email
					coords {
						latitude
						longitude
					}
				}
			}
		}
	}
`;
